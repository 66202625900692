@import url('https://fonts.googleapis.com/css?family=Urbanist');

* {
    padding: 0;
    margin: 0;
}

.home-Screen {
    position: relative;
}

.bg-image {
    height: 40vh;
    width: 100%;
    overflow: hidden;
}

.bg-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.survey-section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
}

.saveit-title-content {
    margin: 15px 20px;
}

.saveit-title-content h2 {
    font-size: 1.6rem;
    font-family: 'Urbanist';
    font-weight: 700;
    color: white;
}

.saveit-title-content h3 {
    font-size: 1.7rem;
    font-family: 'Urbanist';
    font-weight: 700;
    color: white;
}

.saveit-title-content p {
    font-size: 1rem;
    color: #E3E9FF;
    font-family: Urbanist;
    font-weight: 600;
    word-wrap: break-word
}

.survey-section .card {
    background: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    border: 1px #DEE2E6 solid;

}

.survey-section .card h4 {
    padding-top: 24px;
    width: 100%;
    color: #4F575E;
    font-size: 1.125rem;
    font-family: 'Urbanist';
    font-weight: 600;
    word-wrap: break-word;
    letter-spacing: 0.5px;
}

.personal-information {
    padding-top: 50px;
}

.personal-information .form-group {
    padding-top: 1rem;
}

.personal-information .input-group {
    padding-top: 1rem;
}


.personal-information input {
    padding: 10px 14px;
    padding-left: 14px;
    padding-right: 14px;
    border: 1px solid #DEE2E6 !important;
    border-radius: 6px !important;
    font-size: 0.81rem;
    font-family: 'Urbanist';
    font-weight: 500;
    gap: 10px;
}

.personal-information input:focus {
    box-shadow: none !important;
}

.personal-information textarea {
    color: #ADB5BD;
    padding: 10px 14px;
    padding-left: 14px;
    padding-right: 14px;
    border: 1px solid #DEE2E6 !important;
    border-radius: 6px !important;
    font-size: 14px;
    font-family: 'Urbanist';
    font-weight: 500;
    gap: 10px;
}
.personal-information textarea:focus {
    box-shadow: none !important;
}
.personal-information select {}

.personal-information .dropdown-menu {
    display: block !important;
    border-radius: 0;
}

.personal-information select .dropdown-item {
    border-radius: 0;
}

.personal-information .row>* {
    padding-left: 7.5px !important;
    padding-right: 7.5px !important;
}

.relative-position {
    position: relative;
}

.inputContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.input {
    width: 100%;
    padding-right: 40px;
}

.inputIcon {
    position: absolute;
    right: 10px;
}

.custom-dropdown ul {
    width: calc(100% - 15px) !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.custom-dropdown ul li {
    padding: 10px 14px;
    border: 1px #DEE2E6 solid;
}

.custom-dropdown ul li button {
    color: #6A7178;
    font-size: 13px;
    font-family: 'Urbanist';
    font-weight: 600;
    word-wrap: break-word
}

.button-group {
    margin-top: 30px;
}



.button-group button {
    width: 140px;
    height: 46px;
    margin: 0px 12px;
    padding: 10px 14px;
    background-color: #DEE2E6;
    color: #ADB5BD;
    font-size: 14px;
    font-family: Urbanist;
    font-weight: 600;
    word-wrap: break-word;
    border: none;
    letter-spacing: 0.8px;
}

.button-group .active {
    background-color: #466BFF;
    color: white;
}

.button-group .prev {
    background-color:#DEE2E6;
    color: #ADB5BD;
}
.button-group .prevActive {
    background-color: #466BFF1A;
    border: 1px solid #466BFF;
    color: #466BFF;
}

.button-group .prevActive:hover {
    background-color: #466BFF1A;
    color: #466BFF;
}

.button-group .active:hover {
    background-color: #466BFF;
    color: #FFFFFF;
}

.stepper {
    padding: 18px 0 !important;
}
.stepper .completed  {
    border: 1px solid #466BFF !important;
}
.stepper .completed span {
    border: none !important;
}

.stepper .active {
    border: 1px solid #466BFF !important;
}
.stepper .active span {
    border: none !important;
  
}
.stepper__step {
    margin-right: 0;
    z-index: 999;
    cursor: default !important;
}
.stepper__step span {
    font-size: 14px;
    font-family: Urbanist;
    font-weight: 700;
    word-wrap: break-word
}

.stepper__step+div>span {
    color: #466BFF !important;
  
}

.stepper__step:not(.stepper__step--active) .stepper__step-inner-content {
    border: 0 !important;
}

#RFS-ConnectorContainer {
    left: calc(-50% + 1rem - 2px) !important;
    right: calc(50% + 1rem - 1px) !important;
}

.error {
    margin-top: 0;
    color: red;
    font-size: 90%;
}


.card-item{
    margin: 8px 0px;
    padding-left: 14px;
    padding: 10px 24px !important;
    border: 1px solid #DEE2E6;
    border-radius: 6px;
    align-items: center;
    gap: 10px; 
}
.card-item p{
   margin:  0px;
   font-family: Urbanist;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    letter-spacing: 0.5px;

}

.card-items .active{
    background-color: rgba(0, 130.05, 0, 0.10) ;
    border: 1px solid #008200
} 



@media only screen and (max-width: 768px) {

    /* For mobile: */
    .bg-image {
        height: 30vh;
        width: 100%;
        overflow: hidden;
    }

    .saveit-title-content h2 {
        font-size: 1.5rem;
    }

    .saveit-title-content h3 {
        font-size: 20px;
    }

    .saveit-title-content p {
        font-size: 12px
    }

    .survey-section .card h4 {
        padding-top: 12px;
        padding-left: 17px;
        padding-right: 17px;
        font-size: 15px;
        text-align: start;
    }

    .stepper__step {
        width: 20px !important;
        height: 20px !important;
    }
    .stepper__step span {
        font-size: 12px;
        font-family: Urbanist;
        font-weight: 700;
        word-wrap: break-word
    }
    #RFS-ConnectorContainer {
        top: 11px;
        left: calc(-50% + 15px - 4px) !important;
        right: calc(50% + 15px - 4px) !important;
    }


    .personal-information {
        padding-top: 15px;
    }

    .card-body {
        padding: 0px 10px;
    }
   
}

@media only screen and (max-height: 800px) {
    .personal-information {
        padding-top: 30px;
    }
    .stepper {
        padding: 10px 0 !important;
    }
    .personal-information .form-group {
        padding-top: 0.6rem;
    }
    .all-card-item .card-items .card-item{
        margin: 5px 0px;
    }
}
@media only screen and (max-height: 700px) {
    .personal-information {
        padding-top: 0px;
    }
    .stepper {
        padding: 10px 0 !important;
    }
    .personal-information .form-group {
        padding-top: 0.5rem;
    }
    .all-card-item .card-items .card-item{
        margin: 4px 0px;
    }
}